.participantsModalContent {
  margin: 10px 0;
  font-size: 16px;
  font-weight: normal;
  .user {
    font-family: 'Roboto Mono', sans-serif;
    color: black;
    padding: 5px;
    border-radius: var(--radius);
    list-style: none;
    transition: 0.5s;
    margin: 0;
    text-align: start;
    width: max-content;
    span {
      display: inline-block;
      width: max-content;
    }
  }
}

.userBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 6px 8px 8px 8px;
  border-radius: 12px;
  color: white;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
