.calendar {
  overflow: hidden;
  border-bottom: var(--border);
  border-left: var(--border);
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (min-width: 1000px) {
    border-radius: var(--radius);
  }
}

.topBar {
  display: flex;

  .monthAndYear {
    background-color: var(--gray-light);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 100px;
    color: var(--primary);
    font-weight: bold;
    border-right: var(--border);
    border-bottom: var(--border);
    font-size: 12px;
  }
}

.daysLine::-webkit-scrollbar {
  display: none;
}

.daysLine {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  > div {
    border-right: var(--border);
    border-bottom: var(--border);
    display: inline-block;
    width: max((100% - 8px)/7, 150px);
  }

  > div:last-child {
    border-top-right-radius: var(--radius);
  }

  div > div {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-light);
    position: relative;
    z-index: -3;

    > p {
      margin: 0;
    }

    .date {
      font-size: 20px;
      margin-right: 7px;
      margin-bottom: 2px;
    }
    .before,
    .before + p {
      color: var(--gray-dark);
    }
    .today {
      position: relative;
      color: var(--gray-light);
      background-color: var(--primary);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin-right: 2px;
    }
  }
}

.timeLine {
  z-index: 10;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  flex: 1;

  .clockFaces {
    position: relative;
    top: 0;
    left: 0;

    .clockFaceWrapper {
      z-index: 10;
      background-color: var(--background);
      border-right: var(--border);
      border-bottom: 1px solid transparent;
      width: 100px;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      p {
        margin-top: -8px;
        font-size: 1rem;
        float: right;
        color: var(--gray-dark);
      }
    }

    > div:first-child p {
      position: relative;
      top: 10px;
    }
  }
}
