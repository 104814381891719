.interval {
  pointer-events: all;
  position: absolute;
  inset: auto 0;
  z-index: 3;
  border-radius: var(--radius);
  color: var(--background);
  transition: ease-in-out 0.1s;
  .selectAll {
    margin: 0 8px;
    cursor: pointer !important;
    z-index: 20;
    pointer-events: all;
    opacity: 0.5;
    transition: 0.2s;
    @media (min-width: 1700px) {
      position: absolute;
      inset: 8px 6px auto auto;
    }
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
  &.--results:hover,
  &.--focus {
    margin: 0 !important;
    filter: sepia(5%);
    box-shadow: rgba(0, 0, 0, 0.44) 0 0 20px;
    z-index: 10;
  }
  &.--results {
    pointer-events: all !important;
  }
  &:before {
  }
  svg.up,
  svg.down {
    position: absolute;
    left: 50%;
    width: 20px;
  }
  svg.up {
    top: -20px;
    transform: translateX(-50%) scaleY(0.7);
  }
  svg.down {
    bottom: -20px;
    transform: rotate(180deg) translateX(50%) scaleY(0.7);
  }
  .top {
    position: absolute;
    inset: -10px 30px 0 0;
    height: 30px;
    z-index: 14;
    cursor: row-resize;
    pointer-events: all;
    touch-action: pan-x;
  }

  .bottom {
    position: absolute;
    inset: auto 0 -10px;
    height: 30px;
    z-index: 15;
    cursor: row-resize;
    pointer-events: all;
    touch-action: pan-x;
  }

  .cross {
    position: absolute;
    inset: 6px 10px auto auto;
    cursor: pointer !important;
    z-index: 20;
    pointer-events: all;
    opacity: 0.5;
    transition: 0.2s;
    //visibility: hidden;
    //display: none;
  }

  //&:hover .cross,
  //&.--phone .cross{
  //  visibility: visible;
  //  display: block;
  //}
  .cross:hover {
    opacity: 1;
  }

  .clockFace p {
    user-select: none;
    position: relative;
    margin: 8px;
    font-size: 16px;
  }

  * {
    transition: 0.1s;
  }

  .participantsAmount {
    position: absolute;
    inset: 6px 10px auto auto;
    z-index: 20;
    opacity: 0.5;
    display: flex;

    p {
      display: inline-block;
      margin: 3px 5px 5px 5px;
      font-size: 18px;
      font-weight: lighter;
    }

    svg {
      width: 20px;
    }
  }

  .participantsLineWrapper {
    padding: 0 5px 0 15px;
  }
}

.interval:not(.--draggable) {
  pointer-events: none;
}
