.button {
  font-weight: 600;
  height: 2.25rem;
  border-radius: var(--radius);
  box-sizing: border-box;
  border: 1px solid;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.875rem;
  transition-duration: 0.2s;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &.button--primary {
    color: white;
    background: var(--primary);
    border-color: var(--primary);

    &:hover:not(:disabled) {
      color: white;
      background: var(--primary-dark);
      border-color: var(--primary-dark);
    }

    &:disabled {
      color: var(--gray-dark);
      background: var(--gray-light);
      border-color: var(--gray-light);
    }
  }
  &.button--ghost {
    color: var(--primary);
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;

    &:hover:not(:disabled) {
      color: var(--primary-dark);
      background: transparent;
    }

    &:disabled {
      color: var(--gray-dark);
      background: transparent;
    }
  }

  &.button--secondary {
    color: var(--primary);
    background: transparent;
    border-color: var(--primary);

    &:hover:not(:disabled) {
      color: var(--primary-dark);
      background: transparent;
      border-color: var(--primary-dark);
    }

    &:disabled {
      color: var(--gray-dark);
      background: transparent;
      border-color: var(--gray-light);
    }
  }
  &.--stretch {
    width: 100% !important;
    display: block !important;
  }
}
