.reloadSvg {
  fill: var(--primary-light);
  cursor: pointer;
  padding: 1px 0 1px 2px;
  height: 30px;
  width: 30px;
  transition: 0.2s;
  &.--spin {
    transition: 0.2s;
    animation: spin 1s infinite;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
