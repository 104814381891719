.input {
  height: 2.25rem;
  border-radius: var(--radius);
  box-sizing: border-box;
  border: 1px solid var(--gray-dark);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  &:focus {
    border-color: var(--primary);
    outline: none;
  }

  &.error {
    border-color: var(--danger);
    color: var(--danger-dark);

    &::placeholder {
      color: var(--danger-dark);
    }
  }
}
