@import 'variables.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  scroll-behavior: smooth;
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: white;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  box-sizing: border-box;
  margin: 0;
}
.dragInterval {
  cursor: row-resize !important;
  .interval {
    pointer-events: none;
  }
}

@media not handheld {
  ::-webkit-scrollbar {
    background-color: var(--gray-light);
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 5px;
  }
}

.alert {
  background-color: var(--danger);
  position: absolute;
  right: 3%;
  top: 3%;
  padding: 10px;
  border-radius: 5px;
  animation: appear 0.2s ease-in-out;
  color: white;
  z-index: 1000;
  max-width: 94%;
  &.fade {
    animation: disappear 2s ease-in-out;
  }
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes disappear {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    10% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 0;
      transform: translateX(100px);
    }
  }
}
