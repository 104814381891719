.loader {
  width: 2rem;
  height: 2rem;
  display: inline-block;
  position: relative;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
